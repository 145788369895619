import {isThirdPartyCookieSupported} from 'shared/helpers/cookie/thirdPartySupport';

const AUTO_AUTHENTICATE_FLOW = 'auto_authenticate';

export const authWithShield = async (): Promise<void> => {
  try {
    const canUseThirdPartyCookies = await isThirdPartyCookieSupported();

    const isWjb = window.jobBoard.config.organization === 'workable';

    const canSucceed = isWjb || canUseThirdPartyCookies;

    if (!canSucceed) {
      return;
    }

    const autoAuthFlowState = {
      authFlow: AUTO_AUTHENTICATE_FLOW
    };

    const authUrl = new URL('/auth/shield', window.location.origin);

    authUrl.searchParams.set('state', btoa(JSON.stringify(autoAuthFlowState)));

    await fetch(authUrl.toString(), {
      mode: 'no-cors'
    });
  } catch (error) {
    // No handling needed
  }
};
