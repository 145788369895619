/* eslint-disable import/first */
import 'intersection-observer';
import React from 'react';
import ReactDom from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

import App from './app';

ReactDom.render(<App />, document.getElementById('app'));
