import React, {StrictMode, useMemo} from 'react';
import {createBrowserRouter, createRoutesFromElements, RouterProvider} from 'react-router-dom';
import {useBasename} from './contexts/basename/hooks';
import {BasenameProvider} from './contexts/basename/provider';
import Routes from './routes';
import useServiceWorker from './shared/hooks/useServiceWorker';
import './app.scss';

const App = (): JSX.Element => {
  useServiceWorker();

  const {basename} = useBasename();

  const router = useMemo(
    () =>
      createBrowserRouter(createRoutesFromElements(Routes), {
        basename
      }),
    [basename]
  );

  return (
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
};

const AppWithBasename = (): JSX.Element => (
  <BasenameProvider>
    <App />
  </BasenameProvider>
);

export default AppWithBasename;
