import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {ApiError} from 'job-board/api/types/api';
import {isUnauthorizedError} from 'job-board/api/utils/errors';
import createAnalyticsClient from 'job-board/shared/analytics/clients/gtm';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import {clearUserDataDueToError} from 'job-board/shared/redux/userData/slice';
import {AuthChannel, ErrorCode} from 'job-board/shared/services/auth';
import {isServerErrorStatus, isSuccessStatus} from 'job-board/shared/utils/determineHttpStatus';

const baseQuery = fetchBaseQuery({
  baseUrl: __JOB_BOARD_BASE_API_URL__ || window.location.origin
});

const getRequestUrl = (args: string | FetchArgs): string => (typeof args === 'string' ? args : args.url);

const getMetaData = (args: string | FetchArgs): {request: Request} => ({
  request: typeof args === 'string' ? new Request(args) : new Request(args.url, args as RequestInit)
});

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  if (typeof args === 'string' || args.method === 'GET') {
    const requestUrl = getRequestUrl(args);
    const urlWithoutQueryParams = requestUrl.split('?')[0];
    const bakedApiUrl = urlWithoutQueryParams.replace(/^\//, '');

    // The server errors are populated on the top level of initialState
    const globalPreBakedStatus = (window.jobBoard.initialState as PreBakedServerError).status;
    if (globalPreBakedStatus && isServerErrorStatus(globalPreBakedStatus)) {
      delete window.jobBoard.initialState.status;
      return {
        error: {
          data: undefined,
          status: globalPreBakedStatus
        }
      };
    }

    const bakedData = (window.jobBoard.initialState as PreBakedResponse)[bakedApiUrl];

    if (bakedData) {
      delete (window.jobBoard.initialState as PreBakedResponse)[bakedApiUrl];
      const meta = getMetaData(args);

      return isSuccessStatus(bakedData.status)
        ? {
            data: bakedData.data,
            meta
          }
        : {
            error: {
              data: bakedData.data,
              status: bakedData.status
            }
          };
    }
  }

  const result = await baseQuery(args, api, extraOptions);

  if (FEATURE_FLAGS.IS_PROFILE_ENABLED && isUnauthorizedError(result.error as ApiError)) {
    const authChannel = AuthChannel.getInstance();
    authChannel.postMessage({error: {code: ErrorCode.RESPONSE_ERROR}});
    api.dispatch(clearUserDataDueToError());
    void createAnalyticsClient().then(analyticsClient => {
      analyticsClient?.resetHeapIdentity();
    });
  }

  return result;
};

export default customBaseQuery;
