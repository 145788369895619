import {useCallback} from 'react';
import {shallowEqual, useDispatch} from 'react-redux';
import {GetUserDataResponse} from 'job-board/api/types/getUserData';
import {useAnalytics} from 'job-board/contexts/analytics/hooks';
import {useSelector} from 'job-board/redux';
import {HeapProperty} from 'job-board/shared/analytics/events/heap';
import {useLanguage} from 'job-board/shared/hooks/useLanguage';
import {selectIsUserAuthenticated, selectUserData} from './selectors';
import {clearState, clearUserDataDueToError, setUserData, UserDataState} from './slice';

export const useUserDataActions = () => {
  const dispatch = useDispatch();
  const {addHeapEventProperties, heapIdentify} = useAnalytics();
  const {updateLanguage} = useLanguage();

  const updateUserAfterLogin = useCallback(
    (user: GetUserDataResponse) => {
      dispatch(setUserData(user));

      addHeapEventProperties({
        [HeapProperty.LOGGED_IN]: 'true'
      });

      heapIdentify(user.id);

      const userLanguage = user.preferences.language;

      if (userLanguage) {
        void updateLanguage(userLanguage);
      }
    },
    [updateLanguage]
  );

  return {
    clearState: useCallback(() => dispatch(clearState()), []),
    clearUserDataDueToError: useCallback(() => dispatch(clearUserDataDueToError()), []),
    setUserData: useCallback((payload: GetUserDataResponse) => {
      dispatch(setUserData(payload));
    }, []),
    updateUserAfterLogin
  };
};

export const useUserData = (): UserDataState => useSelector(selectUserData, shallowEqual);

export const useIsUserAuthenticated = (): boolean => useSelector(selectIsUserAuthenticated);
