import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {buildUrlSearchParams, checkUrlSearchParamsHaveChanged} from 'job-board/shared/utils/urlSearchParams';
import useSearchRouteUrlParams from './useSearchRouteUrlParams';

export type SearchParamValue = string | boolean | string[] | null | undefined;
export type UpdateSearchJobsParamsPayload = {
  filterByProfile?: boolean;
  location?: string;
  query?: string;
  remote?: boolean;
  selectedJobId?: string;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  [key: string]: SearchParamValue;
};

export type SearchJobParams = {
  filterByProfile?: boolean;
  flag: string;
  location: string;
  query: string;
  remote: boolean;
  selectedJobId: string;
  updateSearchJobsParams: (params: UpdateSearchJobsParamsPayload) => void;
  urlSearchParams: URLSearchParams;
};

const useSearchJobsParams = (): SearchJobParams => {
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const urlParams = useSearchRouteUrlParams();
  const query = urlParams.query || urlSearchParams.get('query') || '';
  const location = urlParams.location || urlSearchParams.get('location') || '';
  const remote = urlParams.remote || urlSearchParams.get('remote') === 'true';
  const filterByProfile = urlSearchParams.get('filterByProfile') === 'true' || undefined;
  const flag = urlSearchParams.get('flag') || '';
  const selectedJobId = urlSearchParams.get('selectedJobId') || '';

  const updateSearchJobsParams = useCallback<SearchJobParams['updateSearchJobsParams']>(
    params => {
      const newUrlSearchParams = buildUrlSearchParams(params, urlSearchParams);
      if (checkUrlSearchParamsHaveChanged(urlSearchParams.toString(), newUrlSearchParams)) {
        setSearchParams(newUrlSearchParams);
      }
    },
    [urlSearchParams]
  );

  return {
    filterByProfile,
    flag,
    location,
    query,
    remote,
    selectedJobId,
    updateSearchJobsParams,
    urlSearchParams
  };
};

export default useSearchJobsParams;
