import React, {createContext, ReactNode, useRef} from 'react';
import type Rollbar from 'rollbar';
import {register} from 'helpers/rollbar';

const ignoreRules = [
  {
    matchers: [/"Script error\."/, /"isCanceled"/g],
    path: 'rollbarPayload.body.trace.exception.message'
  }
];

export const RollbarContext = createContext<Rollbar | null | undefined>(undefined);

export const RollbarProvider = ({children}: {children: ReactNode}): JSX.Element => {
  const rollbar = useRef<Rollbar | null>(register({ignoreRules}));

  return <RollbarContext.Provider value={rollbar.current}>{children}</RollbarContext.Provider>;
};
