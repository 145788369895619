export const mountIframe = src => {
  return new Promise((resolve, reject) => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = src;
    iframe.sandbox = 'allow-scripts allow-same-origin';

    iframe.addEventListener('load', e => {
      resolve(iframe);
    });

    iframe.addEventListener('error', e => {
      reject();
    });

    document.body.appendChild(iframe);
  });
};

export const isThirdPartyCookieSupported = () => {
  return new Promise(resolve => {
    let iframe;
    const messages = [];

    const handleMessage = event => {
      if (event.source !== iframe.contentWindow) {
        return;
      }

      if (event.data === 'MM:3PCsupported' || event.data === 'MM:3PCunsupported') {
        if (iframe) {
          iframe.remove();
        }

        return resolve(event.data === 'MM:3PCsupported');
      }
    };

    window.addEventListener('message', event => {
      if (iframe) {
        handleMessage(event);
      } else {
        messages.push(event);
      }
    });

    mountIframe('https://dcvxs6ggqztsa.cloudfront.net/third-party-cookies-support/production/start.html')
      .then(mountedIframe => {
        iframe = mountedIframe;
        while (messages.length > 0) {
          handleMessage(messages.shift());
        }
      })
      .catch(e => {
        resolve(false);
      });
  });
};
