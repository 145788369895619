import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {updateConsentFromCookie} from 'shared/helpers/analytics/gtmConsent';
import SharedCookieConsent from 'shared/ui/organisms/cookieConsent';
import {watchForConsent} from 'job-board/shared/analytics/helpers/trackConsent';
import {useBackendValue} from 'job-board/shared/hooks/useBackendValue';
import {isTestEnv} from 'job-board/shared/utils/env';

const isBlackListedEnv = isTestEnv();
const isGtmConfigAvailable = __GTM_ID__ && __GTM_AUTH__ && __GTM_ENV__;

const CookieConsent = (): JSX.Element | null => {
  const {t} = useTranslation();
  const {pickBackendUrl} = useBackendValue();
  const [shouldRender, setShouldRender] = useState(!isBlackListedEnv && isGtmConfigAvailable);

  useEffect(() => {
    void watchForConsent().then(() => {
      setShouldRender(false);
    });
  }, []);

  return shouldRender ? (
    <SharedCookieConsent
      companyName={t('page-data.partnerTradeName')}
      cookieSettingsEnabled
      cookiesPolicyUrl={pickBackendUrl('privacyPolicy') || undefined}
      onAcceptDone={updateConsentFromCookie}
      onDeclineDone={updateConsentFromCookie}
      onSaveSettingsDone={updateConsentFromCookie}
      privacyPolicyUrl={pickBackendUrl('privacyPolicy') || undefined}
    />
  ) : null;
};

export default CookieConsent;
