import React, {useCallback, useEffect, useState} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import {Device, useDevice} from 'shared/contexts/device';
import ResponsiveProvider from 'shared/contexts/device/responsive';
import {routesPaths} from 'job-board/routes/constants';
import {MOBILE_FIRST_MEDIA_QUERIES} from 'job-board/shared/mediaQueries';
import Header from '../header';
import SearchBar from '../searchBar';
import {SearchTrigger} from '../searchTrigger';

const Nav = (): JSX.Element => {
  const [device] = useDevice();
  const location = useLocation();
  const [isSearchBarToggledOn, setIsSearchBarToggledOn] = useState(false);

  useEffect(() => {
    setIsSearchBarToggledOn(false);
  }, [location]);

  const isMobile = device === Device.Mobile;

  const isInHomeRoute = !!matchPath(routesPaths.HOME, location.pathname);
  const isInSearchRoute = !!matchPath(`${routesPaths.SEARCH.index}/*`, location.pathname);
  const isInJobRoute = !!matchPath(`${routesPaths.JOB}/*`, location.pathname);
  const isInCandidateRoute = !!matchPath(`${routesPaths.CANDIDATE}/*`, location.pathname);
  const isInProfileSubRoute =
    !!matchPath(`${routesPaths.PROFILE.index}/*`, location.pathname) &&
    !matchPath(routesPaths.PROFILE.index, location.pathname);
  const isInLoginRoute = !!matchPath(routesPaths.LOGIN, location.pathname);
  const isInUnauthorizedRoute = !!matchPath(routesPaths.COMPANY_NOT_AUTHORIZED, location.pathname);

  const isBackButtonVisible = isMobile && isInProfileSubRoute;
  const isSearchButtonVisible = !isInHomeRoute && !isInCandidateRoute && !isInSearchRoute;

  const toggleSearchBarVisibility = useCallback(() => {
    setIsSearchBarToggledOn(isToggledOn => !isToggledOn);
  }, []);

  return (
    <>
      <Header
        isMobile={isMobile}
        isSearchButtonExpanded={isSearchBarToggledOn}
        isSticky={isMobile && isInJobRoute}
        onSearchButtonClick={toggleSearchBarVisibility}
        shouldShowBackButton={isBackButtonVisible}
        shouldShowLoginButton={!isInLoginRoute || !isInUnauthorizedRoute}
        shouldShowSearchButton={isSearchButtonVisible}
        withOnlyCenterAlignedLogo={isInLoginRoute || isInUnauthorizedRoute}
      />
      {isMobile && isInSearchRoute && <SearchTrigger onSearchButtonClick={toggleSearchBarVisibility} />}
      <SearchBar
        dismissable={!isInSearchRoute}
        isFixed={!isMobile && !isInSearchRoute}
        isSticky={!isMobile && isInSearchRoute}
        isToggledOn={isSearchBarToggledOn}
        onDismiss={toggleSearchBarVisibility}
        shouldShowSearchFilters={isInSearchRoute}
      />
    </>
  );
};

const ResponsiveNav = (): JSX.Element => (
  <ResponsiveProvider mobileMediaQueries={MOBILE_FIRST_MEDIA_QUERIES}>
    <Nav />
  </ResponsiveProvider>
);

export default ResponsiveNav;
