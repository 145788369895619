import React from 'react';
import {useTranslation} from 'react-i18next';
import search16 from 'assets/svg/search16.svg?sprite';
import TextBody from 'shared/ui/atoms/text/body';
import EllipsisWithTooltip from 'shared/ui/molecules/ellipsisWithTooltip';
import Svg from 'job-board/shared/components/svg';
import useSearchJobsParams from 'job-board/shared/hooks/useSearchJobsParams';
import styles from './searchTrigger.scss';

type SearchTriggerProps = {
  onSearchButtonClick: () => void;
};

const TOOLTIP_PROPS = Object.freeze({});

export const SearchTrigger = ({onSearchButtonClick}: SearchTriggerProps): JSX.Element => {
  const {t} = useTranslation();
  const {location, query} = useSearchJobsParams();

  const hasSearchTerms = !!query || !!location;

  return (
    <div className={styles.container}>
      <button className={styles.button} data-ui="search-for-jobs-button" onClick={onSearchButtonClick}>
        <Svg className={styles.icon} id={search16.id} />
        <EllipsisWithTooltip tooltipProps={TOOLTIP_PROPS}>
          <span className={styles.text}>
            {hasSearchTerms ? (
              <>
                {!!query && <TextBody>{query}</TextBody>}
                {!!location && <TextBody>{location}</TextBody>}
              </>
            ) : (
              <TextBody secondary>{t('shared.search-input.job-title.placeholder')}</TextBody>
            )}
          </span>
        </EllipsisWithTooltip>
      </button>
    </div>
  );
};
