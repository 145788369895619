import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import ButtonTertiary from 'shared/ui/atoms/button/tertiary';
import JobBoardLogo from 'job-board/shared/components/jobBoardLogo';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import {useBackendValue} from 'job-board/shared/hooks/useBackendValue';
import {ScrollDirection, useWindowScrollDirection} from 'job-board/shared/hooks/useScrollDirection';
import {useUserData} from 'job-board/shared/redux/userData/hooks';
import lazyLoadWithRetry from 'job-board/shared/utils/lazyImport';
import {hasLanguageOptions} from 'job-board/shared/utils/locale';
import BackButton from './components/backButton';
import styles from './header.scss';

const SearchJobsButton = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.searchJobsButton' */ './components/searchJobsButton'),
  'component.searchJobsButton'
);

const LanguagePicker = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.languagePicker' */ './components/languagePicker'),
  'component.languagePicker'
);

const BurgerMenu = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.burgerMenu' */ './components/burgerMenu'),
  'component.burgerMenu'
);

const LoginButton = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.loginButton' */ './components/loginButton'),
  'component.loginButton'
);

const ProfileAvatar = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.profileAvatar' */ './components/profileAvatar'),
  'component.profileAvatar'
);

export type HeaderProps = {
  className?: string;
  isMobile: boolean;
  isSearchButtonExpanded?: boolean;
  isSticky: boolean;
  onSearchButtonClick?: () => void;
  shouldShowBackButton: boolean;
  shouldShowLoginButton: boolean;
  shouldShowSearchButton: boolean;
  withOnlyCenterAlignedLogo: boolean;
};

const Header = ({
  className,
  isMobile,
  isSearchButtonExpanded,
  isSticky,
  onSearchButtonClick,
  shouldShowBackButton,
  shouldShowLoginButton,
  shouldShowSearchButton,
  withOnlyCenterAlignedLogo
}: HeaderProps): JSX.Element => {
  const {t} = useTranslation();
  const {data: userData} = useUserData();
  const {pickBackendUrl} = useBackendValue();
  const scrollDirection = useWindowScrollDirection();

  const hasCompanyAdminUrl = !!userData?.companyAdminUrl;

  const employersPage = userData?.companyAdminUrl ?? pickBackendUrl('employersPage');

  const shouldShowLanguagePicker = shouldShowLoginButton && !userData && hasLanguageOptions();

  const shouldShowForEmployersLink = userData ? hasCompanyAdminUrl : !!employersPage;

  const shouldShowUserButton = FEATURE_FLAGS.IS_PROFILE_ENABLED && shouldShowLoginButton && !hasCompanyAdminUrl;

  const numberOfItemsShown = [shouldShowForEmployersLink, shouldShowLanguagePicker, shouldShowUserButton].filter(
    Boolean
  ).length;

  const shouldShowBurgerMenu = isMobile && numberOfItemsShown > 2;

  return (
    <header
      className={clsx(
        styles.header,
        {
          [styles.hidden]: isSticky && scrollDirection === ScrollDirection.DOWN,
          [styles.mobile]: isMobile,
          [styles.sticky]: isSticky,
          [styles.compact]: shouldShowForEmployersLink
        },
        className
      )}
    >
      <div
        className={clsx(styles['content-container'], {
          [styles['center-aligned']]: withOnlyCenterAlignedLogo
        })}
      >
        {!withOnlyCenterAlignedLogo && shouldShowBackButton ? (
          <BackButton />
        ) : (
          <JobBoardLogo isLinkable isMobile={isMobile} logoAsset={window.jobBoard.assets.headerLogo} />
        )}
        {!withOnlyCenterAlignedLogo && (
          <div className={styles['right-side-container']}>
            {shouldShowBurgerMenu ? (
              <>
                {!withOnlyCenterAlignedLogo && shouldShowSearchButton && (
                  <Suspense fallback={null}>
                    <SearchJobsButton
                      isMobile={isMobile}
                      isSearchButtonExpanded={isSearchButtonExpanded}
                      onSearchButtonClick={onSearchButtonClick}
                    />
                  </Suspense>
                )}
                <Suspense fallback={null}>
                  <BurgerMenu />
                </Suspense>
              </>
            ) : (
              <>
                {shouldShowForEmployersLink && (
                  <ButtonTertiary
                    className={styles['employers-link']}
                    data-ui="employers-link"
                    href={employersPage}
                    rel="noreferrer"
                    target="_self"
                  >
                    {t('header.employers')}
                  </ButtonTertiary>
                )}
                {!withOnlyCenterAlignedLogo && shouldShowSearchButton && (
                  <Suspense fallback={null}>
                    <SearchJobsButton
                      isMobile={isMobile}
                      isSearchButtonExpanded={isSearchButtonExpanded}
                      onSearchButtonClick={onSearchButtonClick}
                    />
                  </Suspense>
                )}
                {shouldShowLanguagePicker && (
                  <Suspense fallback={null}>
                    <LanguagePicker isMobile={isMobile} />
                  </Suspense>
                )}
                {shouldShowUserButton && (
                  <div className={styles.profile}>
                    <Suspense fallback={null}>
                      {userData ? (
                        <ProfileAvatar
                          isMobile={isMobile}
                          shouldShowNewProfileIndication={
                            !userData.hasExploredProfile || !!userData.unverifiedStructuredProfile
                          }
                          url={userData.avatar}
                        />
                      ) : (
                        <LoginButton isMobile={isMobile} />
                      )}
                    </Suspense>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
