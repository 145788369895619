import {matchRoutes, useLocation} from 'react-router-dom';
import startCase from 'lodash/startCase';
import {SEARCH_DYNAMIC_LANDING_ROUTES} from 'job-board/routes/constants';

export const EMPTY_LOCATION_PARAM = 'global';

const C_LEVEL_JOBS = [
  'cco',
  'cdo',
  'ceo',
  'cxo',
  'cfo',
  'chro',
  'clo',
  'cmo',
  'cio',
  'ctio',
  'cko',
  'coo',
  'cpo',
  'cso',
  'cto',
  'cfoo',
  'cao',
  'cbo',
  'cbdo',
  'cgo',
  'ciso',
  'cino',
  'cro',
  'csco',
  'ctpo',
  'ctso',
  'cvo',
  'cwo'
];

const COUNTRIES_TO_BE_UPPER_CASED = ['us', 'usa', 'uae', 'uk'];

const formatStopWords = (string: string): string =>
  string.replace(/ of /gi, ' of ').replace(/ and /gi, ' and ').replace(/ in /gi, ' in ').replace(/ at /gi, ' at ');

const formatQuery = (query = ''): string => {
  const cleanQuery = query
    .toLocaleLowerCase()
    .replace(/jobs/g, '')
    .replace(/remote/g, '')
    .replace(/work-from-home/g, '')
    .replace(/hybrid/g, '')
    .replace(/-/g, ' ')
    .trim();

  return C_LEVEL_JOBS.includes(cleanQuery) ? cleanQuery.toUpperCase() : formatStopWords(startCase(cleanQuery));
};

const formatLocation = (location = ''): string => {
  const lowerCaseLocation = location.toLocaleLowerCase();

  if (lowerCaseLocation === EMPTY_LOCATION_PARAM) {
    return '';
  }

  if (COUNTRIES_TO_BE_UPPER_CASED.includes(lowerCaseLocation)) {
    return lowerCaseLocation.toUpperCase();
  }

  return formatStopWords(startCase(lowerCaseLocation));
};

export type SearchRouteUrlParams = {
  hybrid: boolean;
  location: string;
  query: string;
  remote: boolean;
};

const useSearchRouteUrlParams = (): SearchRouteUrlParams => {
  const currentLocation = useLocation();
  const [match] = matchRoutes(SEARCH_DYNAMIC_LANDING_ROUTES, currentLocation) || [];
  const {location, query} = match?.params || {};
  const isRemote = query?.includes('remote') || query?.includes('work-from-home');
  const isHybrid = query?.includes('hybrid');

  return {
    hybrid: !!isHybrid,
    location: formatLocation(location),
    query: formatQuery(query),
    remote: !!isRemote
  };
};

export default useSearchRouteUrlParams;
